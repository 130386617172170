(function (exports) {
  exports.decideLocalizedMessage = function (error) {
    var errorKey = 'generic'

    if (
      error &&
            ((error.responseJSON && error.responseJSON.errorCode && error.responseJSON.errorCode !== 'TEST') ||
            (error.errorCode && error.errorCode !== 'TEST'))
    ) {
      var errorCode = error.responseJSON ? error.responseJSON.errorCode : error.errorCode

      switch (errorCode) {
        case 'EMAIL_ADDRESS_ALREADY_USED':
          errorKey = 'email_address_already_used'
          break

        case 'USER_NOT_FOUND':
          errorKey = 'user_not_found'
          break

        case 'INVALID_API_KEY':
          errorKey = 'user_not_in_session'
          break

        case 'EXPIRED_API_KEY':
          errorKey = 'user_not_in_session'
          break

        case 'INVALID_PHONE_NUMBER':
          errorKey = 'invalid_phone_number'
          break

        case 'RESET_PASSWORD_LINK_NOT_VALID':
          errorKey = 'reset_password_link_not_valid'
          break

        case 'EXPIRED_RESET_PASSWORD_TIME':
          errorKey = 'reset_password_link_expired'
          break

        case 'WRONG_CREDENTIALS':
          errorKey = 'wrong_credentials'
          break

        case 'EXPIRED_VOUCHER':
          errorKey = 'expired_voucher'
          break

        case 'CHARGING_STATION_ALREADY_ASSOCIATED':
          errorKey = 'charging_station_already_associated'
          break

        case 'SERIAL_NUMBER_NOT_FOUND':
          errorKey = 'serial_number_not_found'
          break

        case 'NOT_A_PARKING_OF_APPLICATION':
          errorKey = 'not_a_parking_of_application'
          break

        case 'INVALID_PARKING_ID':
          errorKey = 'invalid_parking_id'
          break

        case 'CHARGING_PROCESS_IN_PROGRESS':
          errorKey = 'exit_with_charge_in_progress'
          break

        case 'CHARGE_SERVICE_GENERIC_ERROR':
          errorKey = 'charge_service_generic_error'
          break

        case 'PARKING_NOT_FOUND':
          errorKey = 'parking_not_found'
          break

        case 'MISSING_BUSINESS_TIME_FOR_A_DAY':
          errorKey = 'missing_business_time_for_a_day'
          break

        case 'BUSINESS_TIME_CONFLICT':
          errorKey = 'business_time_conflict'
          break

        case 'OPENING_TIME_AFTER_CLOSING_TIME':
          errorKey = 'opening_time_after_closing_time'
          break

        case 'PARKING_IS_MANUAL':
          errorKey = 'parking_is_manual'
          break

        case 'PARKING_HAS_QBOXES_ASSOCIATED':
          errorKey = 'parking_has_qboxes_associated'
          break

        case 'MISSING_BUSINESS_TIMES':
          errorKey = 'missing_business_times'
          break

        case 'MISSING_ACCESS_CONTROL_TYPE':
          errorKey = 'missing_access_control_type'
          break
          
        case 'VOUCHER_OF_THIS_KIND_ALREADY_REDEEMED':
          errorKey = 'voucher_of_this_kind_already_redeemed'
          break

        case 'WRONG_PIN':
          errorKey = 'wrong_pin'
          break

        case 'VOUCHER_USER_ACTIVATION_DATE_EXPIRED':
          errorKey = 'voucher_user_activation_date_expired'
          break

        case 'INACTIVE_VOUCHER':
          errorKey = 'inactive_voucher'
          break

        case 'VOUCHER_ALREADY_REDEEMED':
          errorKey = 'voucher_already_redeemed'
          break
      }
    }

    return errorKey
  }
} (typeof exports === 'undefined' ? this.commonErrorHandling = {} : exports))
